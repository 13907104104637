//  InfiniteSliderCreator v2.0  //
//  Description:  Создаёт бесконечный слайдер с нужными настройками. 
//    InfiniteSliderCreator принимает две группы параметров:
//      - sliderCreatorOptions - параметры самого sliderCreator (работа с touch-событиями)
//      - sliderOptions - параметры InfiniteSlider ('js-default-slider_wrapper', 'custom-name-slider' и проч.)
//
//  Documentation: https://confluence.wargaming...
//
//  Usage:  //
//  Пример правильной инициации Slider:
//    let sliderCreatorOptions = {
//      // swipeControl: false // [optional] Default: true || For switching on/off swipe control
//    },
//    sliderOptions = {
//      container: this.$sliderWrapper, // [required] Default: 'js-default-slider_wrapper'
//      // sliderName: 'custom-name-slider', // [optional] Default: 'default-slider' || For customized slider
//      updateOnResize: true // [optional] Default: false
//    };
//    new InfiniteSliderCreator(sliderCreatorOptions, sliderOptions);


import './hammer.min.js';
import './infinite_slider.js';

export default class InfiniteSliderCreator {
  constructor(sliderCreatorOptions, sliderOptions) { 
    this.sliderCreatorOptions = sliderCreatorOptions;
    this.options = {
      default: {
        swipeControl: true
      },
      final: undefined
    };
    this.sliderOptions = sliderOptions; // Passed directly to the 'InfiniteSlider' class
    this.$sliderWrapper = sliderOptions.container;
    this.infiniteSlider = undefined;
    this.hammertime = undefined;

    this.init();
    this.bindEvents();
  }

  init() {
    this.applyCreatorOptions();

    this.infiniteSlider = new InfiniteSlider(this.sliderOptions);

    this.initHammer();
  }

  bindEvents() {
    if (this.options.final.swipeControl) {
      this.hammertime.on('panend', this.controlSlides.bind(this));
    }
  }

  applyCreatorOptions() {
    this.options.final = $.extend(true, {}, this.options.default, this.sliderCreatorOptions);
  }

  initHammer() {
    const self = this;
      this.$sliderWrapper.each(function () {
        self.hammertime = new Hammer(this, {touchAction: 'pan-y'});
      });
  }

  controlSlides(e) {
    if (Math.abs(e.deltaX) > 50) {
      if (e.deltaX < 0) {
        this.infiniteSlider.gotoNextSlide();
      } else {
        this.infiniteSlider.gotoPrevSlide();
      }
    }
  };
}
