//  TankRotation v1.0  //

//  Description:  //
//  ...
//  Documentation: https://confluence.wargaming...

//  Usage:  //
//  ...

export default class TankRotation {
  constructor(tankRotation) { 
    this.$tankRotation = $(tankRotation);
    this.arrowsStatusElement = this.$tankRotation.parents(".js-tank-rotation_arrows-status")[0];
    this.$arrows = this.$tankRotation.find(".js-tank-rotation_arrows");
    this.video = this.$tankRotation.find(".js-tank-rotation_video")[0];
    this.client = 'portal';
    this.touchDevice = this.checkIfTouch();
    this.options = {
      portal: {
        behavior: 'control', // control, looped-video, img
        controlAutoplay: false, // true / false | for 'control' behavior only
        controlInitialAngle: 30, // 0 - 360 | for 'control' behavior only
        controlSensitivity: 1, // 1 - faster, 3 - slower | for 'control' behavior only
        controlSensitivityTouch: 3, // 1 - faster, 3 - slower | for 'control' behavior only
        loopedVideoPlaybackRate: 0.7 // 0 - 1 | 1 - video original playback rate | for 'looped-video' behavior only
      },
      wgc: {
        behavior: 'looped-video', // control, looped-video, img
        controlAutoplay: false, // true / false | for 'control' behavior only
        controlInitialAngle: 30, // 0 - 360 | for 'control' behavior only
        controlSensitivity: 1, // 1 - faster, 3 - slower | for 'control' behavior only
        controlSensitivityTouch: 3, // 1 - faster, 3 - slower | for 'control' behavior only
        loopedVideoPlaybackRate: 0.5 // 0 - 1 | 1 - video original playback rate | for 'looped-video' behavior only
      },
      videoFPS: Number(this.video.dataset.fps),
      videoDuration: Number(this.video.dataset.videoDuration)
    }
    this.status = {
      pointerDown: false, // mouse up/down status
      videoInitialTime: 0,
      pointerInitialPositionX: 0
    }

    this.init();
    this.bindEvents();
  }

  init() {

    $("video").attr("playsinline", ""); //fixes bug with looped video autoplay on iOS devices
    this.checkIfWGC();


    switch(this.options[this.client].behavior) {
      case 'control':  // Allow user to rotate the tank
        this.correctVideoDuration();
        this.setTankInitialPosition();
        this.initArrowsStatus();
        break

      case 'looped-video':  // Show looped video
        this.playLoopedVideo();
        break
  
      case 'img':  // Show static PNG in WGC
        this.showImg();
        break
    
      default:
        console.log("Check the 'behavior' option for Portal")
        break
    }

  }

  bindEvents() {

    if ( this.options[this.client].behavior == 'control' ) {
      this.$tankRotation.on('mousedown touchstart', (e) => {
        this.startControlVideo(e)
      });
  
      this.$tankRotation.on('mousemove touchmove', _.throttle((e) => {
        if (this.status.pointerDown) {
          this.controlVideo(e);
          this.hideArrows();
        }  
      }, 100));
  
      this.$tankRotation.on('mouseup touchend mouseleave', (e) => {
        this.endControlVideo(e);
      }); 
    }

  }

  checkIfWGC() {
    let path = window.location.href,
        search = path.search('wgc-client');
    if ( search >= 0 ) {
        this.client = 'wgc';
    } else {
        this.client = 'portal';
    };
  }

  checkIfTouch() { // true - touch device | false - not a touch device | works only with Modernizr 
    let html = document.querySelector("html")

    if (html.classList.contains("touchevents")) {
        return true;
      } else {
        return false;
      }
  }

  correctVideoDuration() {
    this.options.videoDuration = this.options.videoDuration - (1 / this.options.videoFPS); // Отними длительность 1 кадра
  }

  setTankInitialPosition() {
    this.video.load(); //required for iOS

    if (this.options[this.client].controlAutoplay) { // start rotation 
      this.video.play();

    } else { // rotate to the specified angle
      let videoShiftIndex = this.options[this.client].controlInitialAngle / 360;
  
      let videoTargetTime = this.calcVideoTargetTime (0, videoShiftIndex);
      this.video.pause(); //required for iOS
      this.video.currentTime = videoTargetTime;
    }

  }

  initArrowsStatus() {
    let elem = this.arrowsStatusElement

    if ( elem.hasAttribute('data-arrows-status') ) { // 'data-arrows-status' exists
      let arrowsStatus = elem.getAttribute('data-arrows-status');
      if (arrowsStatus == 'visible') {
        this.$arrows.addClass('tank-rotation_arrows__active');
      }
    } else { // 'data-arrows-status' doesn't exist
      elem.setAttribute('data-arrows-status', 'visible');
      this.$arrows.addClass('tank-rotation_arrows__active');
    }
  }

  hideArrows() {
    this.$arrows.removeClass('tank-rotation_arrows__active');
    this.arrowsStatusElement.setAttribute('data-arrows-status', 'hidden');
  }

  startControlVideo(e) { // mousedown
    this.status.pointerDown = true;
    this.status.videoInitialTime = this.video.currentTime;
    let pointerPosition = this.getPointerPosition(e, this.$tankRotation[0]);
    this.status.pointerInitialPositionX = pointerPosition.elementX;

    this.video.pause();
  }

  controlVideo(e) {
    let initialPosition = this.status.pointerInitialPositionX,
        currentPosition = this.getPointerPosition(e, this.$tankRotation[0]).elementX,
        controlAreaWidth = this.$tankRotation.outerWidth();

    let pointerShiftIndex = this.getPointerShiftIndex (initialPosition, currentPosition, controlAreaWidth); // Посчитай сдвиг курсора оносительно главного div ( min -1 [от правого до левого края], 0 [сдвига нет], max 1 [от левого до правого края] )
    let videoShiftIndex = this.calcVideoShiftIndex (pointerShiftIndex); // Посчитай, насколько нужно подвинуть playhead video ( -1 полный оборот против вращения, 1 полный оборот по вращению ) 
    let videoTargetTime = this.calcVideoTargetTime (this.status.videoInitialTime, videoShiftIndex);

    this.video.currentTime = videoTargetTime;

  }

  endControlVideo() { // mouseup
    this.status.pointerDown = false;
    this.status.videoInitialTime = 0;
  }

  getPointerPosition(e, element) {
    let clientX,
        clientY;

    if (e.type.includes(`touch`)) { // touchstart || touchmove || touchend || touchcancel
      let { touches, changedTouches } = e.originalEvent ?? e;
      let touch = touches[0] ?? changedTouches[0];
      clientX = touch.clientX;
      clientY = touch.clientY;
    } else if (e.type.includes(`mouse`)) { // mousedown || mouseup || mousemove || mouseover || mouseout || mouseenter || mouseleave
      clientX = e.clientX;
      clientY = e.clientY;
    }

    let pointerPosition = {
      clientX: clientX, // x coordinate within the browser window
      clientY: clientY, // y coordinate within the browser window
      elementX: clientX - element.getBoundingClientRect().left, // x coordinate within the div element
      elementY: clientY - element.getBoundingClientRect().top // y coordinate within the div element
    }
    return pointerPosition
  }

  getPointerShiftIndex (initialPosition, currentPosition, controlAreaWidth) { // 0 - 1 
    let deltaPixels,
        deltaIndex;

    deltaPixels = currentPosition - initialPosition;
    deltaIndex = deltaPixels / controlAreaWidth;
    
    return -deltaIndex;
  }

  calcVideoShiftIndex (pointerShiftIndex){
    let videoShiftIndex,
        controlSensitivity;

    controlSensitivity = this.touchDevice ? this.options[this.client].controlSensitivityTouch : this.options[this.client].controlSensitivity;
    // console.log("controlSensitivity", controlSensitivity) // AUX
    videoShiftIndex = pointerShiftIndex / controlSensitivity;

    return videoShiftIndex;
  }

  calcVideoTargetTime (videoInitialTime, videoShiftIndex) {

    let videoDuration = this.options.videoDuration,
        timeSift = videoDuration * videoShiftIndex,
        targetTime = videoInitialTime + timeSift;

        // console.log("videoDuration", videoDuration) // AUX
        // console.log("timeSift", timeSift) // AUX
        // console.log("targetTime", targetTime) // AUX
        // console.log("targetTime", targetTime) // AUX

    if ( targetTime < 0 ) {
      targetTime = videoDuration + targetTime;
      // console.log("targetTime after correction", targetTime) // AUX
    } else if ( targetTime > videoDuration ) {
      targetTime = targetTime - videoDuration;
      // console.log("targetTime after correction", targetTime) // AUX
    }
    return targetTime;

  }

  playLoopedVideo() {
    this.video.load(); //required for iOS
    this.video.play();
    this.video.playbackRate = this.options[this.client].loopedVideoPlaybackRate;
    this.$tankRotation.addClass('tank-rotation__looped-video');
  }

  showImg() {
    this.$tankRotation.addClass('tank-rotation__img');
  }
  
}