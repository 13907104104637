// v 1.0.0

import videoPlaysInline from '../video-plays-inline/index.js';

export default class videoLoop {
  constructor(video) {
    this.$video = $(video);
    //check double call class
    if (this.$video.data('videoLoopInited')) {
      return false;
    }
    this.options = {};
    this.defaultOptions = { //video def options
      loop: 'loop',
      autoplay: 'autoplay',
      muted: 'muted',
      preload: 'auto'
    };

    this.init();
    this.bindEvents();
  }

  init() {
    this.options = {
      ...this.defaultOptions,
      ...this.$video.data()
    };

    videoPlaysInline(); // bugfix looped video autoplay on iOS devices

    this.$video.data('videoLoopInited', true);
  }

  bindEvents() {
    let self = this;

    $(window).on('load resize', _.throttle(function () {
      self.addVideoFirstScreen(this);
    }));
  }

  addVideoFirstScreen() {
    if ($(window).width() > 1100 && this.$video.find('.release-block_video-wrapp').length === 0) {
      this.$video.empty();
      this.$video.append(`<video src="${this.options.src}" class="release-block_bg-video" ${this.options.loop} ${this.options.autoplay} muted="${this.options.muted}" preload="${this.options.preload}"></video>`);
    }
  }
}