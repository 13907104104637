import amplify from '../amplify.js';
(function($, _, IScroll, amplify) {

    'use strict';

    function HorizontalTabs(options) {
        var SCROLL_DURATION = 500,
            SCROLL_THRESHOLD = 20,
            EASING_TYPE = IScroll.utils.ease.circular;

        var defaultTabsOptions = {
                tabItemSelector: '.js-tab-item',
                tabContentItemSelector: '.js-tab-content-item',
                tabListSelector: '.js-horizontal-tabs-list',
                tabListWrapperSelector: '.js-horizontal-tabs-list-wrapper',
                arrowLeftSelector: '.js-arrow-left',
                arrowRightSelector: '.js-arrow-right',
                shadowLeftSelector: '.js-shadow-left',
                shadowRightSelector: '.js-shadow-right',
                tabsHolderSelector: '.js-horizontal-tabs-holder'
            };

        this.options = _.extend({}, defaultTabsOptions, options);

        var $container = $(this.options.container),
            $tabsList = $(this.options.tabListSelector, $container),
            $tabsListWrapper = $(this.options.tabListWrapperSelector, $container),
            $tabItems = $(this.options.tabItemSelector, $container),
            $tabContentItems = $(this.options.tabContentItemSelector, $container),
            $arrowLeft = $(this.options.arrowLeftSelector, $container),
            $arrowRight = $(this.options.arrowRightSelector, $container),
            $shadowLeft = $(this.options.shadowLeftSelector, $container),
            $shadowRight = $(this.options.shadowRightSelector, $container),
            containerWidth = $container.outerWidth(),
            arrowActiveClass = $container.data('arrowActiveClass'),
            arrowShadowClass = $container.data('shadowActiveClass'),
            activeClasses = {
                plain: $container.data('activeClassPlain'),
                list: $container.data('activeClassList')
            },
            tabsListWidth,
            tabsListScroll;

        if (!$container.length) {
            return;
        }

        tabsListScroll = new IScroll($tabsListWrapper.get(0), {
            mouseWheel: true,
            scrollX: true,
            scrollY: false,
            scrollbars: 'custom',
            scrollOnlyByHorizontalMouseWheel: true,
            mouseWheelPreventDefault: false,
            resizeScrollbars: true,
            click: true,
            snap: '.tab-horizontal_item',
            interactiveScrollbars: true
        });

        this.toggleActive = function(e) {
            var $target = $(e.target),
                toggleActiveElem = function(className, tabsHolderSelector, $elem) {
                    $container
                        .find('.' + className)
                            .removeClass(className)
                        .find(tabsHolderSelector)
                            .css('display', '');

                    $elem.addClass(className);
                },
                $targetElem = $target.closest(this.options.tabItemSelector);

            e.preventDefault();

            if (!$targetElem.length || $targetElem.hasClass(activeClasses.list)) {
                return;
            }

            toggleActiveElem(activeClasses.list, this.options.tabsHolderSelector, $targetElem);
            toggleActiveElem(activeClasses.plain, this.options.tabsHolderSelector, $tabContentItems.eq($tabItems.index($targetElem)));

            this.updateActiveElemPosition($targetElem);
        };

        this.updateActiveElemPosition = function($element) {
            var elementPositionLeft = $element.position().left,
                elementPositionRight = elementPositionLeft + $element.outerWidth();

            if (elementPositionLeft < -1) {
                this.onLeftArrowClick();
            } else if (elementPositionRight > containerWidth) {
                this.onRightArrowClick();
            }
        };

        this.updateArrowsState = function() {
            var tabsListPositionLeft = $tabsList.position().left,
                isArrowLeftVisible = (tabsListPositionLeft <= -SCROLL_THRESHOLD),
                isArrowRightVisible = (tabsListPositionLeft >= (containerWidth - tabsListWidth + SCROLL_THRESHOLD));

            if (!isArrowRightVisible && isArrowLeftVisible) {
                tabsListScroll.goToPage((tabsListScroll.pages.length - 1), 0);
            }

            if (isArrowRightVisible && !isArrowLeftVisible) {
                tabsListScroll.goToPage(0, 0);
            }

            $arrowLeft.toggleClass(arrowActiveClass, isArrowLeftVisible);
            $arrowRight.toggleClass(arrowActiveClass, isArrowRightVisible);
            $shadowLeft.toggleClass(arrowShadowClass, isArrowLeftVisible);
            $shadowRight.toggleClass(arrowShadowClass, isArrowRightVisible);
        };

        this.onLeftArrowClick = function() {
            tabsListScroll.prev();
        };

        this.onRightArrowClick = function() {
            tabsListScroll.next();
        };

        this.onLoadCallback = function() {
            tabsListScroll.refresh();
            tabsListWidth = this.getTabsListWidth();

            this.updateArrowsState();
        };

        this.getTabsListWidth = function() {
            return _.sum($tabItems, function(item) { return $(item).outerWidth(); });
        };

        tabsListScroll.on('scrollEnd', _.bind(this.updateArrowsState, this));
        $container.on('click', this.options.tabListSelector, _.bind(this.toggleActive, this));
        $arrowLeft.on('click', _.bind(this.onLeftArrowClick, this));
        $arrowRight.on('click', _.bind(this.onRightArrowClick, this));

        if (document.fonts) {
            document.fonts.ready.then(_.bind(this.onLoadCallback, this));
        } else {
            $(window).on('load', _.bind(this.onLoadCallback, this));
        }

        amplify.subscribe('resolution:statechanged', _.bind(function(state) {
            containerWidth = $container.outerWidth();
            tabsListWidth = this.getTabsListWidth();

            this.updateArrowsState();
        }, this));
    }
    
    window.HorizontalTabs = HorizontalTabs;

})(jQuery, _, IScroll, amplify);
