(function ($, ResolutionManager) {
    'use strict';

    var DIRECTION_LEFT = 0,
        DIRECTION_RIGHT = 1;

    function InfiniteSlider(options) {
        this.sliderName = options.sliderName ? options.sliderName : 'default-slider';

        this.initOptions(options);
        
        this.init();
      
        this.initSlides();
        
        this.bindEvents();

  
        if (this.options.autoPlay) {
            this.autoPlay();
        }
    }

    InfiniteSlider.prototype.initOptions = function(options) {
        this.defaults = {
            frameSelector: `.js-${this.sliderName}_item`,
            sliderArrowLeftSelector: `.js-${this.sliderName}-arrows_item__left`,
            sliderArrowRightSelector: `.js-${this.sliderName}-arrows_item__right`,
            sliderNavItemsMobileSelector: `.js-${this.sliderName}_element`,
            sliderNavInnerSelector: `.js-${this.sliderName}-nav_item`,
            sliderNavInnerActiveClass: `${this.sliderName}-nav_item__active`,
            sliderOutsideLeftClass: `${this.sliderName}_item__outside-left`,
            sliderOutsideRightClass: `${this.sliderName}_item__outside-right`,
            sliderParent: `.js-${this.sliderName}_parent`,
            autoPlay: false,
            autoPlayTimeout: 5000,
            updateOnResize: false,
            isNation: false
        };
        this.options = $.extend(true, {}, this.defaults, options);

    };

    InfiniteSlider.prototype.init = function() {

        if (this.options.containerId) {
            this.container = document.getElementById(this.options.containerId);
        } else if (this.options.container) {
            this.container = this.options.container;
        }

        if (!this.container) {
            return;
        }

        this.$container = $(this.container);
        this.$sliderContainer = $(this.options.sliderContainer, this.$container);
        this.$slides = $(this.options.frameSelector, this.$container);
        this.$sliderParent = $(this.options.sliderParent, this.$container); 
        this.sliderOutsideLeftClass = this.options.sliderOutsideLeftClass;
        this.sliderOutsideRightClass = this.options.sliderOutsideRightClass;       
        this.prevWindowWidth = undefined;
        this.amountOfSlides = this.$slides.length;
        this.direction = null;
        this.addControls();
        this.$sliderArrowLeft = this.$container.parents(this.options.sliderParent).find(this.options.sliderArrowLeftSelector);
        this.$sliderArrowRight = this.$container.parents(this.options.sliderParent).find(this.options.sliderArrowRightSelector);
        this.$sliderNavItemsMobile = this.$container.parents(this.options.sliderParent).find(this.options.sliderNavItemsMobileSelector, this.$container);
        this.$sliderNavInner = this.$container.parents(this.options.sliderParent).find(this.options.sliderNavInnerSelector, this.$container);
        this.sliderNavActiveClass = this.options.sliderNavInnerActiveClass;
        this.sliderNavActiveSelector = (this.sliderNavActiveClass ? '.' + this.sliderNavActiveClass : '');
        this.sliderNavItemsMobileActiveClass = this.$sliderNavItemsMobile.eq(0).data('activeClass') || '';
    };

    InfiniteSlider.prototype.initSlides = function() {
        this.$initialStateSlides = this.$slides.clone();
        this.$initialStateSlides
            .addClass('js-initial-state-slide')
            .addClass(this.sliderOutsideLeftClass);

        this.$originalSlides = this.$slides.clone();
        this.$activeSlide = this.$slides.filter('.js-active');
        this.activeSlideIndex = this.$activeSlide.index();
        this.$slides.not(this.$activeSlide).remove();

        this.$originalSlides
            .removeClass(this.sliderOutsideLeftClass)
            .removeClass('js-active');

        this.$originalSlides.find('.js-hidden-elems').each(function(index, elem) {
            $(elem).removeClass($(elem).data('hiddenClassName'));
        });

        
        this.updateContainerHeightStart();

        this.updateSliderNav();
        
    };

    InfiniteSlider.prototype.bindEvents = function() {
        var self = this;
        this.$sliderArrowRight.on('click', function() {self.gotoNextSlide();});
        this.$sliderArrowLeft.on('click', function() {self.gotoPrevSlide();});
        this.$sliderNavInner.on('click', function(e) {self.gotoSelectedSlide(e);});
        this.$sliderNavItemsMobile.on('click', function(e) { self.gotoSelectedSlide(e)});

        if (this.options.updateOnResize) {
            $(window).on('resize load', _.debounce(function() {self.updateContainerHeightStart(), 300}));
        }

        if (Modernizr.pagevisibility && this.options.autoPlay) {
            $(document).on('visibilitychange.infinite', function() { {
                if (document.hidden) {
                    self.stopAutoPlay();
                } else {
                    self.autoPlay();
                }
            }});
        }
    };

    InfiniteSlider.prototype.addControls = function() {
        if (this.amountOfSlides > 1){
                this.$container.parent().append(`
                    <div class="${this.sliderName}-arrows">
                        <span class="${this.sliderName}-arrows_item ${this.sliderName}-arrows_item__left js-${this.sliderName}-arrows_item__left">&nbsp;</span>
                        <span class="${this.sliderName}-arrows_item ${this.sliderName}-arrows_item__right js-${this.sliderName}-arrows_item__right">&nbsp;</span>
                    </div>
                `);

                this.$container.parent().append(`
                    <div class="${this.sliderName}-nav">
                        <div class="${this.sliderName}-nav_item ${this.sliderName}-nav_item__0 ${this.sliderName}-nav_item__active js-${this.sliderName}-nav_item" data-type="menu">&nbsp;</div>                        
                    </div>
                `);

            for(let i = 1; i <= this.amountOfSlides - 1; i++){
                this.$container.parent().find(`.${this.sliderName}-nav`).append(`
                    <div class="${this.sliderName}-nav_item ${this.sliderName}-nav_item__${i} js-${this.sliderName}-nav_item" data-type="menu">&nbsp;</div> 
                `);
            }
        }
    };

    InfiniteSlider.prototype.updateContainerHeightStart = function() {
        if (this.options.isNation){
            let windowWidth = $(window).width(),
                maxHeight, $temporaryElements;

            if (windowWidth !== this.prevWindowWidth) {
                this.$container
                    .height('')
                    .append(this.$initialStateSlides.clone());

                $temporaryElements = this.$container.find('.js-initial-state-slide');
                maxHeight = _.max(_.map($temporaryElements.toArray(), function(slide) {return $(slide).outerHeight()} ));
                $temporaryElements.remove();

                if (!isFinite(maxHeight)) {
                    maxHeight = '';
                }

                this.$container.height(maxHeight);

                this.prevWindowWidth = windowWidth;
            }
        }else{
            var sliderNameHeight = this.$container.find(this.options.frameSelector).outerHeight();
            this.$container.css('height', sliderNameHeight);
        }
    };

    InfiniteSlider.prototype.animateSlide = function(newSlideIndex) {
        var self = this;

        let $newSlide, $slideToAnimate, $slideToRemove;
        $newSlide = this.$originalSlides.eq(newSlideIndex).clone();

        if (this.direction === DIRECTION_RIGHT) {
            $newSlide
                .addClass(this.sliderOutsideLeftClass)
                .insertAfter(this.$activeSlide);

            $newSlide.get(0).offsetHeight;
        } else {
            $newSlide
                .addClass(this.sliderOutsideRightClass)
                .insertAfter(this.$activeSlide);

            $newSlide.get(0).offsetHeight;
        }

        $slideToAnimate = $newSlide;
        $slideToRemove = this.$activeSlide;

        if (Modernizr.csstransitions) {
            $slideToAnimate.on('transitionend.transition animationend.transition', function(e) {
                if ($(e.target).is($slideToAnimate)) {
                    $slideToAnimate.off('.transition');
                    setTimeout(function()  {
                        $slideToRemove.remove();
                        $slideToAnimate = $slideToRemove = null;

                    }, 500);

                }
            });
        }
        if (this.direction === DIRECTION_RIGHT) {
            $slideToAnimate.removeClass(this.sliderOutsideLeftClass);
            $slideToRemove.addClass(this.sliderOutsideRightClass);
        } else {
            $slideToAnimate.removeClass(this.sliderOutsideRightClass);
            $slideToRemove.addClass(this.sliderOutsideLeftClass);

        }

        if (!Modernizr.csstransitions) {
            $slideToRemove.remove();
            $slideToAnimate = $slideToRemove = null;
        }
        this.$activeSlide = $newSlide;
        this.activeSlideIndex = newSlideIndex;
        this.updateSliderNav();
        
        setTimeout(function()  {
            self.updateContainerHeightStart();
        }, 1500);


    };

    InfiniteSlider.prototype.updateSliderNav = function() {
        if (this.$sliderNavInner && this.$sliderNavInner.length) {
            this.$sliderNavInner.removeClass(this.sliderNavActiveClass);
            this.$sliderNavInner.eq(this.activeSlideIndex).addClass(this.sliderNavActiveClass);

            this.$sliderNavItemsMobile.removeClass(this.sliderNavItemsMobileActiveClass);
            this.$sliderNavItemsMobile.eq(this.activeSlideIndex).addClass(this.sliderNavItemsMobileActiveClass);
        }

    };

    InfiniteSlider.prototype.gotoSelectedSlide = function(e) {
        var selectedIndex = $(e.currentTarget).index();
        var self = this;

        if (ResolutionManager.getCurrentState() < ResolutionManager.RESOLUTION_DESKTOP && this.options.isNation) {
            selectedIndex = this.$sliderNavItemsMobile.index($(e.currentTarget));
        } else {
            selectedIndex = this.$sliderNavInner.index($(e.currentTarget));
        }

        if (selectedIndex > this.activeSlideIndex) {
            this.direction = DIRECTION_RIGHT;
            this.animateSlide(selectedIndex);
        } else if (selectedIndex !== this.activeSlideIndex) {
            this.direction = DIRECTION_LEFT;
            this.animateSlide(selectedIndex);
        }
        setTimeout(function()  {
            self.updateContainerHeightStart();
        }, 200);
    };

    InfiniteSlider.prototype.gotoNextSlide = function() {
        var self = this;
        let nextIndex = (this.activeSlideIndex < this.amountOfSlides - 1 ? this.activeSlideIndex + 1 : 0);

        if (nextIndex !== this.activeSlideIndex) {
            this.direction = DIRECTION_RIGHT;
            this.animateSlide(nextIndex);
        }
        setTimeout(function()  {
            self.updateContainerHeightStart();
        }, 150);
    };

    InfiniteSlider.prototype.gotoPrevSlide = function() {
        var self = this;

        let prevIndex = (this.activeSlideIndex > 0 ? this.activeSlideIndex - 1 : this.amountOfSlides - 1);

        if (prevIndex !== this.activeSlideIndex) {
            this.direction = DIRECTION_LEFT;
            this.animateSlide(prevIndex);
        }
        setTimeout(function()  {
            self.updateContainerHeightStart();
        }, 150);


    };

    InfiniteSlider.prototype.stopAutoPlay = function() {
        if (this.autoPlayHandle) {
            clearTimeout(this.autoPlayHandle);
        }
    };

    InfiniteSlider.prototype.autoPlay = function() {
        var self = this;
        if (!this.options.autoPlay || this.amountOfSlides < 2 || this.resolutionIndex < ResolutionManager.RESOLUTION_TABLET) {
            return;
        }

        this.stopAutoPlay();

        this.autoPlayHandle = setTimeout(function()  {
            self.gotoNextSlide();
            self.autoPlay();
        }, this.options.autoPlayTimeout);
    };

    window.InfiniteSlider = InfiniteSlider;

})($, ResolutionManager);