//  ScreenName v1.0  //

//  Description:  //
//  ...
//  Documentation: https://confluence.wargaming...

//  Usage:  //
//  ...

import TankRotation from './tank-rotation/index';
import InfiniteSliderCreator from './infinite-slider-creator/index';

export default class TankRotationScreen {
  constructor(tankRotationScreen) { 
    this.$tankRotationScreen = $(tankRotationScreen);  

    this.$sliderWrapper = this.$tankRotationScreen.find('.js-turtle-slider_wrapper');
    this.tankRotationClass = '.js-tank-rotation';
    this.sliderName = 'turtle-slider';

    this.init();
    this.bindEvents();
  }

  init() {
    this.initSlider();
    this.observeSliderForMutation(); // when sliders are changed, init TankRotation 
  }

  bindEvents() {
    $(window).on('load', () => { 
      new TankRotation(this.tankRotationClass);
    });
  }

  initSlider() {
    let sliderCreatorOptions = {
          swipeControl: false // [optional] Default: true
        },
        sliderOptions = {
          container: this.$sliderWrapper, // [required]
          sliderName: this.sliderName, // [optional] Default: 'default-slider'
          updateOnResize: true // [optional] Default: false
        };
    new InfiniteSliderCreator(sliderCreatorOptions, sliderOptions);
  }

  observeSliderForMutation() {
    const observer = new MutationObserver(() => {
      this.initTankRotationOnSliderUpdate();
    });
    observer.observe(this.$sliderWrapper[0], {
      childList: true,
    });
  }

  initTankRotationOnSliderUpdate() {
    let self = this,
        $sliderItems = this.$tankRotationScreen.find(`.js-${this.sliderName}_item`);

    if ( $sliderItems.length > 1 ) { // 1 - конец перелистывания (do nothing), 2 - начало перелистывания (find new slide)  
      $sliderItems.each(function() {
        if ( $(this).hasClass(`${self.sliderName}_item__outside-right`) || $(this).hasClass(`${self.sliderName}_item__outside-left`) ) { // old slide
          return
        } else { // new slide
          new TankRotation(this)
        }
      });
    } 
  
  }

}