export default function setPreview(){
    $('.video-preview_view').each(function () {
        const url = $(this).attr('href');
        if (url.indexOf('wg.360.cn') !== -1) {
            const id = parseInt(url.split('id=')[1]);
            get360preview(id,  (cover) => {
                $(this).find('.video-preview_img').css('background-image', `url("${cover}")`);
            })
        } else if (url.indexOf('youtu') !== -1) {
            var thisSrc = $(this).attr('href').split(".be/")[1];
            $(this).find('.video-preview_img').css('background-image', `url("https://i.ytimg.com/vi/${thisSrc}/hqdefault.jpg")`);
        }
    });
}

let get360preview = function(videoId, onSuccess, onError) {
    $.ajax({
        url: 'https://profile.wg.360.cn/trans/index',
        dataType: 'jsonp',
        data: {
            d: videoId
        },
        success: function(response) {
            var cover = response.data && response.data.cover;
            
            if (response.errno === 0 && cover && typeof onSuccess === 'function') {
                onSuccess(cover);
            }
        },
        error: function() {
            if (typeof onError === 'function') {
                onError();
            }
        }
    });
}

